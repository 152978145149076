import "./AboutAgentMore.css";

import {useEffect} from "react";

import {useLocation} from "react-router-dom";

import Navbar from "../Navbar/Navbar";

import MenuOverlay from "../../pages/MenuOverlay/MenuOverlay";

const emailIcon = require('../../data/emailicon.png');

export default function AboutAgentMore()
{
    const location = useLocation();
    const agent = location.state

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <Navbar />
        
        <section className="aboutMore">
            <section className="aboutMore-left">
                <img className="aboutMore-left-headshot" src={agent.headshot} />
            </section>
            
            <section className="aboutMore-right">
                <div className="aboutMore-right-name">{agent.name}</div>
                <div className="aboutMore-right-title-dre">
                    <div className="aboutMore-right-title">
                        {agent.title} • DRE#
                    </div>
                    <div className="aboutMore-right-dre">
                        {agent.dreNum}
                    </div>
                </div>
                <div className="aboutMore-right-phone">{agent.phoneNum}</div>
                <div className="aboutMore-right-emails">
                    <img className="aboutMore-right-email-icon"
                    src={emailIcon}
                    />
                    <div className="aboutMore-right-email-address">
                        {agent.email}
                    </div>
                </div>
                <div className="aboutMore-right-desc">
                    {agent.desc}
                </div>
            </section>
        </section>
        <MenuOverlay />
        </>
    )
}