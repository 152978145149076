import { useState } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { editRecentSale } from "../../utilities/recentSales-api";

export default function EditRecentSaleForm({ sale })
{
    // Used for obtaining params
    const location = useLocation();
    //Parse params for id
    const searchParams = new URLSearchParams( location.search );
    //copy id
    const saleId = searchParams.get( "saleId" )

    // Used for redirecting back to listing after submition
    const navigate = useNavigate();


    // Used for updating params after redirecting

    const [newSale, addNewSale] = useState({
        name: "",
        price: "",
        address: "",
        beds: "",
        baths: "",
        sqFootage: "",
        longDescription: "",
    })

    const [error, setError] = useState("");

    function handleChange( evt )
    {
        addNewSale({...newSale, [evt.target.name]: evt.target.value});
        setError("");
    }

    async function handleSubmit( evt )
    {
        evt.preventDefault();
        try
        {
            
            //updated Listing
            await editRecentSale( saleId, newSale );
        
            // redirect after update
            navigate(`/recent_sales`);
        }
        catch
        {
            setError( "Submition Failed - Please Try Again" )
        }
    }
    
    //update state after redirect
    

    return (
        <>
        <h1>Edit Recent Sale Form</h1>
        <form onSubmit={ handleSubmit }>
        <label>Edit Recent Sale</label>
            <input
                type="text"
                name="name"
                value={ newSale.name }
                onChange={ handleChange }
            />
            <label>Edit Price</label>
            <input
                type="number"
                name="price"
                value={ newSale.price }
                onChange={ handleChange }
            />
            <label>Edit Address</label>
            <input
                type="text"
                name="address"
                value={ newSale.address }
                onChange={ handleChange }

            />
            <label>Edit Baths</label>
            <input
                type="number"
                name="baths"
                value={ newSale.baths }
                onChange={ handleChange }
            />
            <label>Edit Bed</label>
            <input
                type="number"
                name="beds"
                value={ newSale.beds }
                onChange={ handleChange }
            />
            <label>Edit Square Footage</label>
            <input
                type="number"
                name="sqFootage"
                value={ newSale.sqFootage }
                onChange={ handleChange }
            />
            <label>Edit Description</label>
            <input
                type="text"
                name="longDescription"
                value={ newSale.longDescription }
                onChange={ handleChange }
            />
            <button type="submit">Submit</button>
        </form>
        <p className="error-message">{ error }</p>
        </>
    )
}