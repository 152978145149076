import MenuOverlay from "../MenuOverlay/MenuOverlay"


export default function HomeSearch()
{
    return (
        <>
        <h1>Home Search</h1>
        <MenuOverlay />
        </>
    )
}