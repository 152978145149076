import "./AuthPage.css";

import SignUpForm from "../../components/SignUpForm/SignUpForm";

import LoginForm from "../../components/LoginForm/LoginForm";

import Account from "../../components/Account/Account";

export default function AuthPage({user, setUser}) {
    return (
        <div className="authpage">
            {
            user ?
            <Account></Account>
            :
            <>
            {/* <SignUpForm setUser={setUser} /> */}
            <LoginForm setUser={setUser} />
            </>
            }
        </div>
    )
}