import sendRequest from "./send-request";
const BASE_URL = "/api/featuredListings";

export async function getFeaturedListings()
{
    return sendRequest(BASE_URL);
}

export async function addFeaturedListing( newListing )
{
    console.log(newListing)
    return sendRequest( `${BASE_URL}/create`, "POST", newListing );
}

export async function editFeaturedListing( listingId, newListing )
{
    return sendRequest( `${BASE_URL}/edit/${listingId}`, "POST", newListing )
}

export async function deleteFeaturedListing( listingId )
{
    return sendRequest( `${BASE_URL}/delete/${listingId}`, "DELETE" );
}

// export async function getFeaturedListing( listingId )
// {
//     return sendRequest( `${BASE_URL}/${listingId}` );
// }
