import "./Contact.css";

export default function ContactUs()
{
    return (
        <div id="contact" className="contactUs">
            <div className="contactUs-heading">Send Us an Inquiry</div>
            <section className="contactUs-form-container">
                <form className="contactUs-form">
                    <div className="form-name-email">
                        
                        <div className="form-name">
                            <div>Name</div>
                            <input type="text" id="name" name="name" required />
                        </div>

                        <div className="form-email">
                            <div>Email</div>
                            <input type="email" id="email" name="email" required />
                        
                        </div>
                    </div>

                    <div className="form-phone">
                        <div>Phone Number</div>
                        <input type="tel" id="phone" name="phone" required />
                    </div>

                    <div className="form-message">
                        <div>Message</div>
                        <input id="message" name="message" required></input>
                    </div>
                    <div className="form-submit">
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </section>
        </div>
    )
}