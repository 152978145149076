import Navbar from "../../components/Navbar/Navbar";

import { Link, useLocation } from "react-router-dom"

import "../../pages/About/About.css";

const dannyIcon = require('../../data/Daniel Fisher.png');
const bobbyIcon = require('../../data/Bobby.png');
const claudiaIcon = require('../../data/Claudia.png');
const deborahIcon = require('../../data/Deborah.png');
const kattIcon = require('../../data/Katt.png');

export default function AboutAgent({aboutAgents})
{
    console.log(aboutAgents)
    return (
        <>
            {aboutAgents[0].headshot ? 
            <>
            <div className='about-card1'>
                <img src={aboutAgents[0].headshot} alt='Danny Fisher' className='front'/>
                <h1 className='about-card-left-bottom'>{aboutAgents[0].name}</h1>
                <div className='about-card-left-top'>{aboutAgents[0].title}</div>
                <div className='icon-back'>DRE# {aboutAgents[0].dreNum}</div>
                <div className='about-card-num'>{aboutAgents[0].phoneNum}</div>
                <Link className="about-card-more" to={`/aboutAgentMore?aboutId=${aboutAgents[0]._id}`} state={aboutAgents[0]}>More</Link>
            </div>
            <div className='about-card1'>
                <img src={aboutAgents[1].headshot} alt='Danny Fisher' className='front'/>
                <h1 className='about-card-left-bottom'>{aboutAgents[1].name}</h1>
                <div className='about-card-left-top'>{aboutAgents[1].title}</div>
                <div className='icon-back'>DRE# {aboutAgents[1].dreNum}</div>
                <div className='about-card-num'>{aboutAgents[1].phoneNum}</div>
                <Link className="about-card-more" to={`/aboutAgentMore?aboutId=${aboutAgents[1]._id}`} state={aboutAgents[1]}>More</Link>
            </div>
            <div className='about-card1'>
                <img src={aboutAgents[2].headshot} alt='Danny Fisher' className='front'/>
                <h1 className='about-card-left-bottom'>{aboutAgents[2].name}</h1>
                <div className='about-card-left-top'>{aboutAgents[2].title}</div>
                <div className='icon-back'>DRE# {aboutAgents[2].dreNum}</div>
                <div className='about-card-num'>{aboutAgents[2].phoneNum}</div>
                <Link className="about-card-more" to={`/aboutAgentMore?aboutId=${aboutAgents[2]._id}`} state={aboutAgents[2]}>More</Link>
            </div>
            <div className='about-card1'>
                <img src={aboutAgents[3].headshot} alt='Danny Fisher' className='front'/>
                <h1 className='about-card-left-bottom'>{aboutAgents[3].name}</h1>
                <div className='about-card-left-top'>{aboutAgents[3].title}</div>
                <div className='icon-back'>DRE# {aboutAgents[3].dreNum}</div>
                <div className='about-card-num'>{aboutAgents[3].phoneNum}</div>
                <Link className="about-card-more" to={`/aboutAgentMore?aboutId=${aboutAgents[3]._id}`} state={aboutAgents[3]}>More</Link>
            </div>
            </>
             :null}
        </>
    )
}