import { useState, useEffect } from "react";
import { useLocation, useNavigate, } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { addFeaturedListing } from "../../utilities/featuredListings-api";

import { getFeaturedListings } from "../../utilities/featuredListings-api";


export default function AddFeaturedListingForm({ featuredListingItems, setFeaturedListingItems, setNewListingAdded })
{
    // Used for obtaining params
    const location = useLocation();
    //Parse params for id
    const searchParams = new URLSearchParams( location.search );
    //copy id
    const listingId = searchParams.get( "listingId" )

    // Used for redirecting back to listing after submition
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFeaturedListingItems = async () => {
            try {
                const updatedListings = await getFeaturedListings();
                setFeaturedListingItems(updatedListings)
            }
            catch (err) {
                console.log("Error fetching updated Listing");
            }
        }
        fetchFeaturedListingItems();
    },[])

    // Used for updating params after redirecting

    const [newListing, addNewListing] = useState({
        price: "",
        address: "",
        beds: "",
        baths: "",
        sqft: "",
        type: "",
        mls: "",
        desc: "",
        coverImg: "",
    })

    const [error, setError] = useState("");

    // Handle File Upload
    const [coverImage, setCoverImage] = useState(null);

    const [galleryImages, setGalleryImages] = useState([]);

    const handleCoverImage = ( evt ) => {
        setCoverImage( evt.target.files[0] );
    }

    const handleGalleryImages = ( evt ) => {
        setGalleryImages( evt.target.files );
    }

    function handleChange( evt )
    {
        if (evt.target.name === "coverImg") {
            addNewListing(prevState => ({
                ...prevState,
                [evt.target.name]: evt.target.files[0]
        }));
        } else {
            addNewListing({...newListing, [evt.target.name]: evt.target.value});
            setError("");
        }
    }

    async function handleSubmit( evt )
    {
        evt.preventDefault();
        try
        {
            const formData = new FormData();
            for (const key in newListing) {
                if (newListing[key] instanceof File) {
                    formData.append(key, newListing[key], newListing[key].name);
                } else {
                    formData.append(key, newListing[key]);
                }
        }
            //updated Listing
            await addFeaturedListing( formData );
            setNewListingAdded( true );
            // redirect after update
            // navigate(`/#featuredListingsid`);
        }
        catch
        {
            setError( "Submition Failed - Please Try Again" )
        }
    }
    
    //update state after redirect
    

    return (
        <section className="add-featuredList">
            <div className="add-featuredList-heading">Add Featured Listing</div>
            <form className="add-featuredList-form" onSubmit={ handleSubmit }>

                <div className="add-featuredList-name">
                    <div>Address:</div>
                    <input
                        type="text"
                        name="address"
                        value={ newListing.address }
                        onChange={ handleChange }
                        className="add-featuredList-name-input"
                    />
                </div>


                <div className="add-featuredList-row-col-inputs">
                    <div className="add-featuredList-price-baths-type">
                        <div className="add-featuredList-price">
                            <div>Price:</div>
                            <input
                                type="number"
                                name="price"
                                value={ newListing.price }
                                onChange={ handleChange }
                                className="add-featuredList-price-input"
                            />
                        </div>

                        <div className="add-featuredList-baths">
                            <div>Baths:</div>
                            <input
                                type="number"
                                name="baths"
                                value={ newListing.baths }
                                onChange={ handleChange }
                                className="add-featuredList-baths-input"
                            />
                        </div>

                        <div className="add-featuredList-type">
                            <div>Type:</div>
                            <input
                                type="text"
                                name="type"
                                value={ newListing.type }
                                onChange={ handleChange }
                                className="add-featuredList-type-input"
                            />
                        </div>
                    </div>

                    <div className="add-featuredList-sqft-beds-mls">

                        <div className="add-featuredList-sqft">
                            <div>Square Footage:</div>
                            <input
                                type="number"
                                name="sqft"
                                value={ newListing.sqft }
                                onChange={ handleChange }
                                className="add-featuredList-sqft-input"
                            />
                        </div>

                        <div className="add-featuredList-beds">
                            <div>Beds:</div>
                            <input
                                type="number"
                                name="beds"
                                value={ newListing.beds }
                                onChange={ handleChange }
                                className="add-featuredList-beds-input"
                            />
                        </div>

                        <div className="add-featuredList-mls">
                            <div>MLS:</div>
                            <input
                                type="number"
                                name="mls"
                                value={ newListing.mls }
                                onChange={ handleChange }
                                className="add-featuredList-mls-input"
                            />
                        </div>
                    </div>
                </div>

                <div className="add-featuredList-image-cover">
                    <div className="add-featuredList-coverImage">
                        <div>Cover Image:</div>
                        <input
                            type="file"
                            name="coverImg"
                            onChange={ handleChange }
                        />
                    </div>
                </div>

                {/* <div className="add-featuredList-image-cover">
                    <div className="add-featuredList-galleryImages">
                        <div>Gallery Images:</div>
                        <input
                            type="file"
                            name="galleryImages"
                            value={ newListing.galleryImages }
                            onChange={ handleChange }
                        />
                    </div>
                </div> */}

                <div className="add-featuredList-desc">
                    <div>Description</div>
                    <input
                        type="text"
                        name="desc"
                        value={ newListing.desc }
                        onChange={ handleChange }
                    />
                </div>

                <div className="add-featuredList-button">
                    <button className="add-featuredList-submit" type="submit">Submit</button>
                </div>
            </form>
            <p className="error-message">{ error }</p>
        </section>
    )
}