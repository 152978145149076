import { Link } from "react-router-dom";

export default function RecentSale({ sale })
{
    return (
        <>
        <section className="recentSale-sale">
            <h3>{ sale.name }</h3>
            <div className="recentSale-brief-desc-price">
                <label>$</label>
                <div className="recentSale-value-price">{ sale.price }</div>
            </div>

            <div className="recentSale-brief-desc">
                <div className="recentSale-brief-desc-beds">
                    <label>Bed: </label>
                    <div className="recentSale-value-bed">{ sale.beds }</div>
                </div>
                <div className="recentSale-brief-desc-baths">
                    <label>Baths: </label>
                    <div className="recentSale-value-baths">{ sale.baths }</div>
                </div>
                <div className="recentSale-brief-desc-sqFootage">
                    <label>Square Footage: </label>
                    <div className="recentSale-value-sqfootage">{ sale.sqFootage }</div>
                </div>
            </div>

            {sale.name ? <>
            <Link to={`/editSale?saleId=${sale._id}`} className="recentSale-edit-link" state={ sale } >Edit</Link>
            </>: null}
        </section>
        </>
    )
}