import "../../pages/FeaturedListings/FeaturedListings.css"

import { Link } from "react-router-dom";

import stockhouse from "../../data/stockhouse.jpg";

export default function RentList({ rent })
{  
    return (
        <>
        <section className="featuredList-listing">
            <div className="featuredList-mainImage">
                <img src={rent.coverImg} alt="house" className="featuredList-mainImage-img" />
            </div>
            <div className="featuredList-value-price">${ rent.price }/mo</div>
            <div className="featuredList-address">{ rent.address }</div>
            <div className="featuredList-brief-desc">
                <div className="featuredList-value-bed">{rent.beds } beds•</div>
                <div className="featuredList-value-baths">{ rent.baths } baths•</div>
                <div className="featuredList-value-sqfootage">{ rent.sqft } sq ft•</div>
                <div className="featuredList-value-type">{ rent.type }</div>
            </div>
            <div className="featuredList-mls">MLS # { rent.mls }</div>

            {rent.address ? <>
            <Link to={`/rentDetails?rentId=${rent._id}`} className="featuredList-edit-link" state={ rent } >See More</Link>
            </>: null}
        </section>
        </>
    )
}