import { Component } from 'react';
// First function call down the pipeline in the API to Sign Up a User 
import { signUp } from "../../utilities/users-services";

export default class SignUpForm extends Component {
    //inputs to be sent/checked by database
    state = {
        email: '',
        password: '',
        confirm: '',
        error: '',
    }

    //this function updates state object^^
    handleChange = (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value,
            error: ''
        })
    }

    //this function copies state into an object and sends it to the database api for authorization
    handleSubmit = async (evt) => {
        // handleSubmit gets called when the evt gets updated. preventDefault() ensures this function
        // only gets called when the submittion button is clicked
        evt.preventDefault();

        try {
            //copy state to new object
            const formData = {
                email: this.state.email,
                password: this.state.password,
            }
            // pass copy of state to db api asyncronously. The value returned is copied to user for 
            // session authorization usage
            const user = await signUp(formData);
            //update props for session authorization usage which updates state declared in App.jsx
            this.props.setUser( user );
        } catch {
            this.setState({ error: 'Sign Up Failed-Try Again' })
        }
    }

    render() {
        // set truthy value of disable to false to ensure no submition when passwords do not match
        const disable = this.state.password !== this.state.confirm;
        return (
            <div>
                <div className='form_container'>
                    <form autoComplete='off' onSubmit={ this.handleSubmit }>
                        <label>Admin Id</label>
                        <input type="text" name="email" value={ this.state.email } onChange={ this.handleChange } required />
                        <label>Password</label>
                        <input type="password" name="password" value={ this.state.password } onChange={ this.handleChange } required />
                        <label>Confirm</label>
                        <input type="password" name="confirm" value={ this.state.confirm } onChange={ this.handleChange } required />
                        <button type="submit" disabled={ disable }>Sign Up</button>
                    </form>
                </div>
                <p className='error_message'>{ this.state.error }</p>
            </div>
        )
    }
}