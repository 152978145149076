import { useEffect } from "react";
import { getRecentSales } from "../../utilities/recentSales-api";

import RecentSalesForm from "../../components/RecentSalesForm/RecentSalesForm";
import RecentSale from "../../components/RecentSale/RecentSale";

export default function RecentSales({ recentSalesItems, setRecentSalesItems })
{
    // Updates State when RecentSales are editted or deleted
    useEffect(() => {
        const fetchUpdatedSales = async() => {
            try
            {
                const updatedSales = await getRecentSales();
                setRecentSalesItems( updatedSales );
            }
            catch
            {
                console.log( "Error fetching updated Sales" );
            }
        }
        fetchUpdatedSales();
    }, [])

    return (
        <>
        <h1><b>Recent Sales</b></h1>
        <main className="recentsales-main">

        <RecentSalesForm recentSalesItems={ recentSalesItems } setRecentSalesItems={ setRecentSalesItems } />
        {recentSalesItems.map(sale=>(
            <RecentSale key={ sale._id } sale={ sale } />
        ))}
        </main>
        </>
    )
}