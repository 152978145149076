import "./InquiryForm.css";

import { useState } from "react";

import { addInquiry } from "../../utilities/inquiry-api";

export default function InquiryForm()
{

    const [inquiry, setInquiry] = useState({
        address: "",
        email: "",
        firstName: "",
        lastName: "",
    })

    const [error, setError] = useState("");

    function handleChange( evt )
    {
        setInquiry({...inquiry, [evt.target.name]: evt.target.value});
        setError("");
    }

    async function handleSubmit( evt )
    {
        evt.preventDefault();
        try {
            // POST request
            const inq = await addInquiry( inquiry );
            // update state in App.jsx
            setInquiry([...inquiry, inq]);            

        } catch {
            setError("Submition Failed - Please Try Again");
        }
    }

    return(
        <div className="inquiryForm-main">
            <div className="inquiryForm-heading">
                Can't Find the Home You're Looking for?
            </div>
            <div className="inquiryForm-subheading">
                Leave us an inquiry with the address and your 
                information and we'll schedule an in-person 
                consultation
            </div>

            <form onSubmit={handleSubmit} className="inquiryForm-adds-email-names">
                <div className="inquiryForm-adds-email">
                    <div >Address:</div>
                    <input
                        type="text"
                        name="address"
                        value={ inquiry.address }
                        onChange={ handleChange }
                        required
                    />
                    <div>Email:</div>
                    <input
                        type="email"
                        name="email"
                        value={ inquiry.email }
                        onChange={ handleChange }
                        required
                    />
                </div>

                <div className="inquiryForm-names">
                    <div>First Name:</div>
                    <input
                        type="text"
                        name="firstName"
                        value={ inquiry.firstName }
                        onChange={ handleChange }
                        required
                    />
                    <div>Last Name:</div>
                    <input
                        type="text"
                        name="lastName"
                        value={ inquiry.lastName }
                        onChange={ handleChange }
                        required
                    />
                </div>

                <div className="inquiryForm-desc">
                    <div>Description:</div>
                    <input 
                        type="text"
                        name="desc"
                        value={ inquiry.desc }
                        onChange={ handleChange }
                        required
                    />
                </div>

                <button type="submit" className="inquiryForm-submit">Submit</button>
            </form>
            {/* <p className="error-message">{ error }</p> */}
        </div>
    )
}