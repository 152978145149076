import sendRequest from "./send-request";
const BASE_URL = "/api/recentSales";

// used in App.jsx and RecentSales.jsx
export async function getRecentSales()
{
    return sendRequest(BASE_URL);
}

// used in RecentSalesForm.jsx
export async function addRecentSale( newSale )
{
    return sendRequest( `${BASE_URL}/create`, "POST", newSale );
}

// used in RecentSale.jsx
export async function editRecentSale( saleId, newSale )
{
    return sendRequest( `${BASE_URL}/edit/${saleId}`, "POST", newSale );
}

