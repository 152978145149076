import './HomesForSale.css';

import { useEffect, useState } from 'react';

import InquiryForm from "../../components/InquiryForm/InquiryForm";

import Navbar from "../../components/Navbar/Navbar";

import MenuOverlay from '../MenuOverlay/MenuOverlay';

import HomesForSaleForm from './HomesForSaleForm';

import HomesForSaleList from "./HomeForSaleList";

import { getHomes } from "../../utilities/buy-api";

export default function HomesForSale({user, currentListingItems, setCurrentListingItems}) {

    const [newListAdded, setNewListAdded] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , [])

    useEffect(() => {
        const fetchUpdatedListing = async() => {
            try
            {
                const updatedListings = await getHomes();
                setCurrentListingItems( updatedListings )
            }
            catch(err)
            {
                console.log("Error fetching updated homes");
            }
        }
        fetchUpdatedListing();
    }
    , [newListAdded])

    return (
        <>
        <Navbar />
        <div className='homesForSale-main'>
            <h1 className='homesforSale-title'>Homes For Sale</h1>
            <InquiryForm />

            { currentListingItems && currentListingItems.length > 0 ?
            currentListingItems.map(listing=>(
                <HomesForSaleList key={ listing._id } listing={listing} />
            )):null}
            {user ?
            <HomesForSaleForm setCurrentListingItems={setCurrentListingItems} setNewListAdded={setNewListAdded} />
            : null}
        </div>
        <MenuOverlay />
        </>
    );
}