import "../../components/FeaturedListMore/FeaturedListMore.css";

import {useEffect} from "react";

import {useLocation, useNavigate} from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";

import MenuOverlay from "../../pages/MenuOverlay/MenuOverlay";

import { deleteHome } from "../../utilities/buy-api";

export default function HomeForSaleMore() {
    const location = useLocation();
    const listing = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const deleteListing = async () => {
        try {
            const response = await deleteHome(listing._id);
            console.log('Response:', response);
            navigate('/');
        } catch (error) {
            console.error('Error deleting listing:', error);
            alert('An error occurred while deleting the listing');
          }
        }
      
    return(
        <>
        <Navbar />
        <section className="featuredListMore">
            <section className="featuredListMore-left">
                <img src={listing.coverImg} alt="house" className="featuredListMore-left-img" />
            </section>
            
            <section className="featuredListMore-right">
                <div className="featuredListMore-right-price">${listing.price}</div>
                <div className="featuredListMore-right-address">{listing.address}</div>
                <div className="featuredListMore-bed-bath-sqft-type">
                    <div className="featuredListMore-beds">{listing.beds} beds • </div>
                    <div className="featuredListMore-baths">{listing.baths} baths • </div>
                    <div className="featuredListMore-sqft">{listing.sqft} sq ft • </div>
                    <div className="featuredListMore-type">{listing.type}</div>
                </div>
                <div className="featuredListMore-mls">MLS # {listing.mls}</div>
                <div className="featuredListMore-desc">{listing.desc}</div>
                <button className="featuredList-delete" onClick={deleteListing}>Delete Listing</button>
            </section>
        </section>
        <MenuOverlay />
        </>
    )
}
