import './About.css';

import { useEffect } from 'react';

import {Link} from "react-router-dom";

import AboutAgents from "../../components/AboutAgent/AboutAgent";

import Navbar from '../../components/Navbar/Navbar';

import MenuOverlay from '../MenuOverlay/MenuOverlay';

const dannyIcon = require('../../data/Daniel Fisher.png');
const bobbyIcon = require('../../data/Bobby.png');
const claudiaIcon = require('../../data/Claudia.png');
const deborahIcon = require('../../data/Deborah.png');
const kattIcon = require('../../data/Katt.png');

export default function About({}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , [])
    return (
        <>
        <Navbar />
        <section className="About1">
            <div id="aboutSection">Meet the Team</div>
        </section>
        <section className='About2'>
            <div className='about-card1'>
                    <img src={dannyIcon} alt='Danny Fisher' className='front'/>
                    <h1 className='about-card-left-bottom'>Daniel Fisher</h1>
                    <div className='about-card-left-top'>Broker</div>
                    <div className='icon-back'>DRE# 1228516</div>
                    <div className='about-card-num'>(760) 885-7628</div>
                    <Link className="about-card-more" to={"/aboutAgentMore?aboutId=666565b1fb1b2e8632ac19a6"} state={
                        {
                            name: 'Daniel Fisher',
                            title: 'Broker',
                            dreNum: '1228516',
                            phoneNum: '(760) 885-7628',
                            email: "daniel@parkplacerealty.com",
                            headshot: dannyIcon,
                            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                    }}>More</Link>
                </div>
                <div className='about-card1'>
                    <img src={deborahIcon} alt='Danny Fisher' className='front'/>
                    <h1 className='about-card-left-bottom'>Deborah Nielson-Foor</h1>
                    <div className='about-card-left-top'>Agent</div>
                    <div className='icon-back'>DRE# 1359383</div>
                    <div className='about-card-num'>(760) 701-7572</div>
                    <Link className="about-card-more" to={"/aboutAgentMore?aboutId=66656657fb1b2e8632ac19a7"} state={{
                        name: 'Deborah Nielson-Foor',
                        title: 'Agent',
                        dreNum: '1359383',
                        phoneNum: '(760) 701-7572',
                        email: "deb@parkplacerealty.com",
                        headshot: deborahIcon,
                        desc: "As a local resident of Tri Community, Deb knows our area inside and out. Experienced agent in national companies as ReMax and Century 21, Deb has brought to PARK PLACE REALTY this knowledge and is now able to also offer a closer , more humanity approach to clients that later became her friends. Debora is a well traveled person as she has resided in San Francisco and Hawaiian islands"
                    }}>More</Link>
                </div>
                <div className='about-card1'>
                    <img src={kattIcon} alt='Danny Fisher' className='front'/>
                    <h1 className='about-card-left-bottom'>Katherine Zoll</h1>
                    <div className='about-card-left-top'>Agent</div>
                    <div className='icon-back'>DRE# 1477660</div>
                    <div className='about-card-num'>(951) 490-9727</div>
                    <Link className="about-card-more" to={"/aboutAgentMore?aboutId=6665671efb1b2e8632ac19a8"} state={{
                        name: 'Katherine Zoll',
                        title: 'Agent',
                        dreNum: '1477660',
                        phoneNum: '(951) 490-9727',
                        email: "kat@parkplacereatly.com",
                        headshot: kattIcon,
                        desc: "With Kat Zoll, buy, sell, rent or airbnb usually goes to a happy end. As a PARK PLACE REALTY original, she knows her business and goes beyond client's expectations listing and selling homes not only in her hometown, Wrightwood, but also in places down the hill as Norco, Hemet and Yorba Linda."
                    }}>More</Link>
                </div>
                <div className='about-card1'>
                    <img src={claudiaIcon} alt='Danny Fisher' className='front'/>
                    <h1 className='about-card-left-bottom'>Claudia Campbell</h1>
                    <div className='about-card-left-top'>Agent</div>
                    <div className='icon-back'>DRE# 1948857</div>
                    <div className='about-card-num'>(816) 419-3199</div>
                    <Link className="about-card-more" to={"/aboutAgentMore?aboutId=6665676ffb1b2e8632ac19a9"} state={{
                        name: 'Claudia Campbell',
                        title: 'Agent',
                        dreNum: '1948857',
                        phoneNum: '(816) 419-3199',
                        email: "claud@parkplacerealty.com",
                        headshot: claudiaIcon,
                        desc: "PARK PLACE REALTY agent, singer, Music in The Pines event co director, former American Airlines stewardess, mother, grandmother, Claudia Campbell has accomplished a lot in her life.  For  her as an agent, the most important thing is helping people fulfill their dreams."
                    }}>More</Link>
                </div>


            {/* {/* <AboutAgents aboutAgents={aboutAgents} />
            {/* {aboutAgents.map( agent=>(
                 <AboutAgents key={agent._id} agent={agent} />
            )
             )}} }} */}
            
        </section>
        <MenuOverlay />
        </>
    );
}