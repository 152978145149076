import './Navbar.css';

import { HashLink as HashLink } from 'react-router-hash-link';

import { Link } from "react-router-dom";

import About from '../../pages/About/About';
// ppr logo
const pprLogo = require("../../data/ppr-logo.jpeg");
export default function Navbar()
{

    return (
        <nav>
            <div className="nav-items">
                <div className="nav-left-items">
                    <Link className="link" to="/">
                        <img className="home-pprLogo" src={ pprLogo } alt="Park Place Realty"/>
                    </Link>
                    <div className="nav-left-info">
                        <div className=" nav-left-contact">(760)249-1001</div>
                        <div className='nav-left-dre'>DRE# 01228516</div>
                        <div className='nav-left-address'>6039 Park Drive, Wrightwood, CA 92397</div>
                        <div className='nav-left-email'>P.O. Box 39</div>
                    </div>
                </div>
                <div className="nav-right-item">
                    <Link to="/buy">Listings</Link>
                    <Link to="/rent">Rentals</Link>
                    {/* <HashLink to="/#contactUs">List with Us</HashLink> */}
                    <Link to="/about">About</Link>
                </div>
            </div>

            {/* <MenuOverlay setUser={ setUser }  /> */}
        </nav>
    );
}
