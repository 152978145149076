import sendRequest from "./send-request";
const BASE_URL = "/api/rent";

export async function addRent(rentData)
{
    return sendRequest(`${BASE_URL}/create`, "POST", rentData);
}

export async function getRent()
{
    return sendRequest(BASE_URL);
}

export async function deleteRent( rentId )
{
    return sendRequest(`${BASE_URL}/delete/${rentId}`, "DELETE");
}
