import "./LoginForm.css";

import { useState } from "react";

import { useNavigate } from "react-router-dom";

//import all named exports attached to a usersAPI object from utilities
import * as usersService from "../../utilities/users-services";

export default function LoginForm({ setUser }) {
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        email: "",
        password: "",
    })

    const [error, setError] = useState("");

    function handleChange( evt )
    {
        setCredentials( {...credentials, [evt.target.name]: evt.target.value} );
    }

    async function handleSubmit(evt)
    {
        evt.preventDefault();
        try {
            const user = await usersService.login( credentials );
            setUser( user );
            navigate("/");
        } catch {
            setError("Log In Failed - Try Again");
        }
    }

        return (
            <div>
                <div className="login_form_container">
                    <form autoComplete="off" onSubmit={handleSubmit}>
                        <label>Admin ID</label>
                        <input type="text" name="email" value={credentials.email} onChange={handleChange} required />
                        <label>Password</label>
                        <input type="password" name="password" value={credentials.password} onChange={handleChange} required />
                        <button type="submit">Log In</button>
                    </form>
                </div>
                <p className="error-message">{error}</p>
            </div>
        )
    }