import './Rent.css';

import { useEffect } from "react";

import { getRent } from "../../utilities/rent-api";

import InquiryForm from '../../components/InquiryForm/InquiryForm';

import Navbar from '../../components/Navbar/Navbar';

import MenuOverlay from '../MenuOverlay/MenuOverlay';

import HomesForRentForm from "./HomesForRentForm";

import RentList from "../../pages/Rent/RentList";

export default function Rent({user, rentItems, setRentItems})
{

    useEffect(() => {
        window.scrollTo(0, 0);
    }
    , [])

    // Updates State when Featured Listings are editted or deleted
    useEffect(() => {
        const fetchUpdatedListing = async() => {
            try
            {
                const updatedListings = await getRent();
                setRentItems( updatedListings )
            }
            catch(err)
            {
                console.log("Error fetching updated rent");
            }
        }
        fetchUpdatedListing();
    }, [])

    return (
        <>
        <Navbar />
        <div className='rent-main'>
            <h1 className='rent-title'>Rentals</h1>
            <InquiryForm />
            {rentItems.map(rent=>(
                <RentList key={ rent._id } rent={rent} />
            ))}
            {user ?
            <HomesForRentForm rentItems={rentItems} setRentItems={setRentItems} />
            : null}
        </div>
        <MenuOverlay />
        </>
    )
}