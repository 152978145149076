import { useState } from "react";
import { addRecentSale } from "../../utilities/recentSales-api";

export default function RecentSalesForm({ recentSalesItems, setRecentSalesItems })
{
    const [newSale, addNewSale] = useState({
        name: "",
        price: "",
        address: "",
        beds: "",
        baths: "",
        sqFootage: "",
        longDescription: "",
    })

    const [error, setError] = useState("");

    function handleChange( evt )
    {
        addNewSale({...newSale, [evt.target.name]: evt.target.value});
        setError("");
    }

    async function handleSubmit( evt )
    {
        evt.preventDefault();
        try {
            // POST request
            const newRecSale = await addRecentSale( newSale );
            // update state in App.jsx
            setRecentSalesItems([...recentSalesItems, newRecSale]);
            

        } catch {
            setError("Submition Failed - Please Try Again");
        }
    }

    return (
        <>
        <h2>Recent Sales Form</h2>
        <form onSubmit={handleSubmit}>
            <label>Add Recent Sale</label>
            <input
                type="text"
                name="name"
                value={ newSale.name }
                onChange={ handleChange }
                required
            />
            <label>Price</label>
            <input
                type="number"
                name="price"
                value={ newSale.price }
                onChange={ handleChange }
                required
            />
            <label>Address</label>
            <input
                type="text"
                name="address"
                value={ newSale.address }
                onChange={ handleChange }
                required
            />
            <label>Baths</label>
            <input
                type="number"
                name="baths"
                value={ newSale.baths }
                onChange={ handleChange }
                required
            />
            <label>Bed</label>
            <input
                type="number"
                name="beds"
                value={ newSale.beds }
                onChange={ handleChange }
                required
            />
            <label>Square Footage</label>
            <input
                type="number"
                name="sqFootage"
                value={ newSale.sqFootage }
                onChange={ handleChange }
                required
            />
            <label>Description</label>
            <input
                type="text"
                name="longDescription"
                value={ newSale.longDescription }
                onChange={ handleChange }
                required
            />
            <button type="submit">Submit</button>
        </form>
        <p className="error-message">{ error }</p>
        </>
    )
}