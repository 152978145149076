import "../FeaturedListings/FeaturedListings.css";

import {useEffect} from "react";

import {useLocation, useNavigate} from "react-router-dom";

import Navbar from "../../components/Navbar/Navbar";

import MenuOverlay from "../../pages/MenuOverlay/MenuOverlay";

import { deleteRent } from "../../utilities/rent-api";

export default function RentMore()
{

    const location = useLocation();
    const rent = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const deleteListing = async () => {
        try {
            const response = await deleteRent(rent._id);
            console.log('Response:', response);
            // if (response.status === 200) {
            //     alert('Listing deleted successfully');
            navigate('/');
            // } else {
            //     alert('Something went wrong');
            // }
        } catch (error) {
            console.error('Error deleting listing:', error);
            alert('An error occurred while deleting the listing');
          }
        }
      
    return(
        <>
        <Navbar />
        <section className="featuredListMore">
            <section className="featuredListMore-left">
                <img src={rent.coverImg} alt="house" className="featuredListMore-left-img" />
            </section>
            
            <section className="featuredListMore-right">
                <div className="featuredListMore-right-price">${rent.price}/mo</div>
                <div className="featuredListMore-right-address">{rent.address}</div>
                <div className="featuredListMore-bed-bath-sqft-type">
                    <div className="featuredListMore-beds">{rent.beds} beds • </div>
                    <div className="featuredListMore-baths">{rent.baths} baths • </div>
                    <div className="featuredListMore-sqft">{rent.sqft} sq ft • </div>
                    <div className="featuredListMore-type">{rent.type}</div>
                </div>
                {/* <div className="featuredListMore-mls">MLS # {rent.mls}</div> */}
                <div className="featuredListMore-desc">{rent.desc}</div>
                <button className="featuredList-delete" onClick={deleteListing}>Delete Listing</button>
            </section>
        </section>
        <MenuOverlay />
        </>
    )
}