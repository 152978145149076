import "./Rent.css"

import { useState } from "react";
import {addRent} from "../../utilities/rent-api";

export default function HomesForRentForm({rentItems, setRentItems})
{

    const [newRent, setNewRent] = useState({
        address: "",
        beds: "",
        baths: "",
        type: "",
        mls: "",
        price: "",
        sqft: "",
        coverImg: "",
        desc: "",
    })

    const [error, setError] = useState("");

    const [coverImage, setCoverImage] = useState(null);

    const handleChange = ( evt ) => {
        if (evt.target.name === "coverImg") {
            setNewRent(prevState => ({
                ...prevState,
                [evt.target.name]: evt.target.files[0]
            }));
        } else {
            setNewRent({...newRent, [evt.target.name]: evt.target.value});
            setError("");
        }
    }

async function handleSubmit(evt)
    {
    evt.preventDefault();
    try{
        const formData = new FormData();
        for (const key in newRent) {
            if (newRent[key] instanceof File) {
                formData.append(key, newRent[key], newRent[key].name);
            } else {
                formData.append(key, newRent[key]);
            }
        }

        const newRentListing = await addRent(formData);
        setRentItems([...rentItems, newRentListing]);
    }
    catch {
        setError("Error: Please try again");
    }
}


    return (
        <section className="add-homeForSale">
            <div className="add-homeForSale-heading">Add Home for Rent</div>
            <form className="add-homeForSale-form" onSubmit={ handleSubmit }>

                <div className="add-homeForSale-name">
                    <div>Address:</div>
                    <input
                        type="text"
                        name="address"
                        value={ newRent.address }
                        onChange={ handleChange }
                        className="add-homeForSale-name-input"
                    />
                </div>


                <div className="add-homeForSale-row-col-inputs">
                    <div className="add-homeForSale-price-baths-type">
                        <div className="add-homeForSale-price">
                            <div>Price:</div>
                            <input
                                type="number"
                                name="price"
                                value={ newRent.price }
                                onChange={ handleChange }
                                className="add-homeForSale-price-input"
                            />
                        </div>

                        <div className="add-homeForSale-baths">
                            <div>Baths:</div>
                            <input
                                type="number"
                                name="baths"
                                value={ newRent.baths }
                                onChange={ handleChange }
                                className="add-homeForSale-baths-input"
                            />
                        </div>

                        <div className="add-homeForSale-type">
                            <div>Type:</div>
                            <input
                                type="text"
                                name="type"
                                value={ newRent.type }
                                onChange={ handleChange }
                                className="add-homeForSale-type-input"
                            />
                        </div>
                    </div>

                    <div className="add-homeForSale-sqft-beds-mls">

                        <div className="add-homeForSale-sqft">
                            <div>Square Footage:</div>
                            <input
                                type="number"
                                name="sqft"
                                value={ newRent.sqft }
                                onChange={ handleChange }
                                className="add-homeForSale-sqft-input"
                            />
                        </div>

                        <div className="add-homeForSale-beds">
                            <div>Beds:</div>
                            <input
                                type="number"
                                name="beds"
                                value={ newRent.beds }
                                onChange={ handleChange }
                                className="add-homeForSale-beds-input"
                            />
                        </div>

                        <div className="add-homeForSale-mls">
                            <div>MLS:</div>
                            <input
                                type="number"
                                name="mls"
                                value={ newRent.mls }
                                onChange={ handleChange }
                                className="add-homeForSale-mls-input"
                            />
                        </div>
                    </div>
                </div>

                <div className="add-homeForSale-image-cover">
                    <div className="add-homeForSale-coverImage">
                        <div>Cover Image:</div>
                        <input
                            type="file"
                            name="coverImg"
                            onChange={ handleChange }
                        />
                    </div>
                </div>

                {/* <div className="add-featuredList-image-cover">
                    <div className="add-featuredList-galleryImages">
                        <div>Gallery Images:</div>
                        <input
                            type="file"
                            name="galleryImages"
                            value={ newListing.galleryImages }
                            onChange={ handleChange }
                        />
                    </div>
                </div> */}

                <div className="add-homeForSale-desc">
                    <div>Description</div>
                    <input
                        type="text"
                        name="desc"
                        value={ newRent.desc }
                        onChange={ handleChange }
                    />
                </div>

                <div className="add-homeForSale-button">
                    <button className="add-homeForSale-submit" type="submit">Submit</button>
                </div>
            </form>
            <p className="error-message">{ error }</p>
        </section>
    )
}