import sendRequest from "./send-request";
const BASE_URL = "/api/buy";

export async function getHomes()
{
    return sendRequest(BASE_URL);
}

export async function addHome( homeData )
{
    // console.log(homeData)
    return sendRequest(`${BASE_URL}/create`, "POST", homeData);
}

export async function deleteHome( homeId )
{
    return sendRequest(`${BASE_URL}/delete/${homeId}`, "DELETE");
}
