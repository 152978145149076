import './MenuOverlay.css';

import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// Import for logout function
// import * as userService from "../../utilities/users-services";

// ********************** Images **********************
const locationWinter = require("../../data/location-day-3.jpeg");
const facebookIcon = require("../../data/facebook.png");
const instagramIcon = require("../../data/instagram.png");
const yelpIcon = require("../../data/Zillow.png");

export default function MenuOverlay({ isOpen, setIsOpen })
{
     
    return (
        <main className="menuOverlay-main">
            <div className="menuOverlay-body">
                <div className="menuOverlay-left">
                    <div className='menuOverlay-heading'>
                        <div className='menuOverlay-heading-name'>Park Place Realty</div>
                        <div className='menuOverlay-heading-phone'>Call us: (760) 249-1001</div>
                    </div>


                    <section className="menuOverlay-buying">
                        <div className="menuOverlay-label">Buying</div>
                        <Link className="menuOverlay-ref" to="/buy">Buy With Us</Link>
                        <HashLink className="menuOverlay-ref" to="/#featuredListingsid">See Our Featured Listings</HashLink>
                    </section>

                    <section className="menuOverlay-selling">
                        <div className="menuOverlay-label">Selling</div>
                        <HashLink to="#contactUs" className="menuOverlay-ref">Sell With Us</HashLink>
                    </section>

                    <section className="menuOverlay-renting">
                        <div className="menuOverlay-label">Renting</div>
                        <Link className="menuOverlay-ref" to="/rent">Rent with Us</Link>
                    </section>

                    <section className="menuOverlay-about">
                        <div className="menuOverlay-label">About Us</div>
                        <Link className="menuOverlay-ref" to="/about">Meet the Team</Link>
                        <HashLink className="menuOverlay-ref" to="#contactUs">Contact Us</HashLink>
                    </section>

                </div>
                <section className="menuOverlay-content">
                    <img src={ locationWinter } alt="Our location during the winter" width="500" height="auto" />
                    <div className='content-label'>
                        <div className="content-address">6039 Park Drive, Wrightwood, CA 92397</div>
                        <div className="content-number">(760)249-1001</div>
                        <div className="content-number">(760)249-1002</div>
                        <div className="content-number">Fax: (760)249-6455</div>
                        <a href="mailto:daniel@parkplacerealty.com" className="content-email">daniel@parkplacerealty.com</a>
                        <div className="content-icons">
                            <Link to="https://www.facebook.com/groups/113132292038585/">
                                <img className="content-facebook-icon" src={ facebookIcon } alt="facebook icon" width="50" height="auto" />
                            </Link>
                            <Link to="https://www.instagram.com/parkplacerealtywrightwood/">
                                <img className="content-instagram-icon" src={ instagramIcon } alt="instagram icon" width="50" height="auto" />
                            </Link>
                            {/* <Link><img className="content-zillow-icon" src={ yelpIcon } alt="facebook icon" width="50" height="auto" /></Link> */}
                        </div>
                        <Link className='admin' to="/signup">Admin</Link>
                    </div>
                </section>

            </div>
        </main>
    )
}